import PublicationsList from '../components/PublicationsList'

const Publications = () => {
    return (
      <div className="writing-content">
          <PublicationsList/>
      </div>
      
  )
  };
  
  export default Publications;