import ReadingList from '../components/ReadingList'

const Reading = () => {
    return (
      <div className="reading-content">
          <ReadingList />
      </div>
      
  )
  };
  
  export default Reading;