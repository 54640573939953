import WorkList from '../components/WorkList'

  const Work = () => {
      return (
        <div className="work-content">
            <WorkList/>
        </div>
      )
    };
    
    export default Work;