import Contacts from "../components/ContactComponent";

import '../styles/Contact.css'

const Contact = () => {
    return (
        <div className="contact-content">
            <Contacts/>
        </div>
        
    )
  };
  
  export default Contact;