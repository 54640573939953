import SchoolList from '../components/SchoolList'

  const Education = () => {
      return (
        <div className="school-content">
            <SchoolList/>
        </div>
      )
    };
    
    export default Education;